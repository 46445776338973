import React from 'react';
import Head from 'next/head';

const Meta = ({ title, keyword, desc, children }) => {
  return (
    <div>
      <Head>
        <title>{title}</title>
        <meta name='description' content={desc} />
        <meta name='keyword' content={keyword} />
        <link rel='icon' type='image/png' href='/icon.png' sizes='96x96' />
        <link rel='icon' type='image/svg+xml' href='/icon.svg' />
        <link rel='shortcut icon' href='/favicon.ico' />
        <link rel='apple-touch-icon' sizes='180x180' href='/apple-icon.png' />
        <meta name='apple-mobile-web-app-title' content='Bit5' />
        <link rel='manifest' href='/manifest.json' />
        {children}
      </Head>
    </div>
  );
};

Meta.defaultProps = {
  title: 'Bit5 | Create. Collect. Connect.',
  keyword:
    'bitcoin, blockchain, crypto, crypto collectibles, crypto makretplace, cryptocurrency, digital items, market, nft, dnft, dynamic nft, nft marketplace, nft next js, NFT react, non-fungible tokens, virtual asset, wallet',
  desc: 'Bit5 marketplace for crypto collectibles and non-fungible tokens (NFTs). Buy, sell, and discover exclusive digital items.',
};

export default Meta;
